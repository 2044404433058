@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

:root {
  --fontFamily: "Inter", sans-serif;
  --bgColor: #07124D;
  --textColor: #D3D3D3;
  --white: #FBFBFB;
  --orange: #E55025;
  --orange-hover: #BB411E;
  --green: #28B34F;
  --lightBlue: #5869BF;
}

::-webkit-scrollbar {
  width: .4em;
}

::-webkit-scrollbar-track {
  background-color: var(--bgColor);
  border-radius: 10px;
  margin-block: .5em;
}

::-webkit-scrollbar-thumb {
  background-color: var(--bgColor);
  border-radius: 10px;
  border: var(--textColor) 1px solid;
}

body {
  background-color: var(--bgColor);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

textarea {
  resize: none;
}

.container {
  margin: 0 auto;
  padding-inline: 70px;
  max-width: 1440px;
  width: 100%;
  overflow-y: hidden;
  position: relative;
  
}

.heading {
  font-style: italic;
  font-weight: 500;
  color: var(--white);
}

@media (max-width: 1024px) {
  .container {
    padding-inline: 35px;
  }
}

@media (max-width: 700px) {
  .container {
    padding-inline: 20px;
  }
  .heading {
    font-size: 28px;
  }
}

@media (max-width: 400px) {
  .heading {
    font-size: 24px;
  }
}