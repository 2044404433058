nav {
    display: grid;
    align-items: center;
    margin-top: 30px;
    margin-inline: auto;
    max-width: 1440px;
    width: 100%;
    height: 80px;
    position: sticky;
    top: 0;
    background: var(--bgColor);
    z-index: 9;
}

.nav-options {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.links a{
    font-size: 16px;
    font-weight: 500;
    color: var(--white);
}

.links-container {
    height: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.links {
    padding-block: 10px;
    gap: 5px;
}

@media screen and (min-width: 1024px) {
    .nav-center {
        padding-inline: 70px;
    }
}

@media screen and (min-width: 900px) {
    .indicator {
        position: absolute;
        content: "";
        width: 0;
        height: 3px;
        left: 0;
        bottom: 25px;
        transform: skewX(-15deg);
        background-color: var(--lightBlue);
        transition: all .3s ease-in;
    }

    .nav-toggle {
        display: none;
        outline: none;
        border: none;
    }

    .nav-center {
        padding-inline: 40px;

        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .nav-header {
        padding: 0;
    }

    .links-container {
        height: auto !important;
    }

    .links {
        display: flex;
        gap: 30px;
    }

    .link {
        transition: all .2s ease-in-out;
    }

    .link:hover {
        color: var(--lightBlue);
    }

    .nav-address {
        display: none;
    }
}

@media screen and (max-width: 900px) {
    nav {
        margin: 0;
        padding-top: 20px;
    }
    .nav-header {
        padding-inline: 20px;
    }
    .nav-toggle {
        width: 90px;
        height: 40px;

        border: 1px solid var(--orange);
        outline: none;
        background: transparent;

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: skewX(-20deg);
    }

    .nav-toggle span {
        position: relative;
        content: "";
        width: 30px;
        height: 3px;
        margin-block: 3px;
        background: var(--white);

        transition: all .3s ease-in-out;
    }

    .nav-toggle.active span:nth-child(1) {
        height: 2px;
        transform: translate(0px, 10px) rotate(45deg);
    }

    .nav-toggle.active span:nth-child(2) {
        opacity: 0;
    }

    .nav-toggle.active span:nth-child(3) {
        width: 26px;
        transform: translate(0px, -8px) rotate(-45deg);
    }

    .links-container {
        background: var(--bgColor);
        margin-top: 20px;
        padding-inline: 20px;
        box-shadow: 0px 4px 6px var(--lightBlue);
        text-align: right;
    }

    .links {
        padding-block: 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .links a {
        margin-block: 20px;
        font-size: 32px;
    }

    .links li {
        padding-block: 10px;
        transition: all .3s ease-in-out;
    }

    .links li:hover {
        color: var(--bgColor);
        padding-right: 10px;
        background: rgba(88, 105, 191, 0.3);
    }

    .nav-address {
        display: flex;
        justify-content: center;
        gap: 40px;
    }

    .nav-address a {
        color: var(--textColor);
        opacity: .5;
        transition: all .3s ease-in-out;
    }

    .nav-address a:hover {
        opacity: 1;
        color: var(--lightBlue);
    }
}

@media screen and (max-width: 480px) {
    .indicator {
        display: none;
    }
    nav {
        padding-block: 20px;
        margin-top: 0;
    }

    .logo {
        width: 100px;
    }

    .nav-toggle {
        width: 65px;
        height: 30px;

        border: 1px solid var(--orange);
        outline: none;
        background: transparent;

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transform: skewX(-20deg);
    }

    .nav-toggle span {
        position: relative;
        content: "";
        width: 22px;
        height: 2px;
        margin-block: 3px;
        background: var(--white);

        transition: all .3s ease-in-out;
    }
    .nav-toggle.active span:nth-child(1) {
        height: 1.6px;
        transform: translate(0px, 8px) rotate(45deg);
    }
    .nav-toggle.active span:nth-child(3) {
        width: 20px;
        transform: translate(0px, -9px) rotate(-45deg);
    }

    .links-container {
        background: var(--bgColor);
        margin-top: 20px;
        padding-inline: 20px;
    }

    .links-container li{
        padding-block: 15px;
    }

    .link {
        font-size: 32px;
        font-weight: 700;
    }
}

