.project-detail-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 50px;
}

.video-player {
    max-width: 800px;
    width: 90%;
}

.video-player > video {
    width: 100%;
    height: 100%;
}

.project-description p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: var(--textColor);
}

.project-detail-images {
    max-width: 1300px;
    width: 100%;

    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, 18rem);
    gap: 10px;
}

.project-detail-images img {
    width: 100%;
    height: 18rem;
    object-fit: cover;
}

.backBtn {
    padding: 15px 35px;

    border: 2px solid var(--green);
    outline: none;
    background: transparent;

    color: var(--green);
    font-size: 20px;
    text-transform: capitalize;

    display: flex;
    align-items: center;
}

@media (max-width: 1024px) {
    .project-detail-container {
        padding-inline: 20px;
    }
}

@media (max-width: 640px) {
    .video-player {
        width: 95%;
    }

    .project-detail-images {
        grid-template-columns: repeat(2, minmax(9rem, 9rem));
        justify-items: center;
    }

    .project-detail-images img {

        width: 9rem;
        height: 9rem;
    }
}

@media (max-width: 300px) {
    .project-detail-container {
        grid-template-columns: repeat(1, 9rem);
    }
}