.hidden {
    display: none;
    transition: all .3s ease-in;
}

.nav-languages {
    width: 90px;
    height: 40px;
    position: relative;
    z-index: 11;
}


.language, .languages {
    font-size: 14px;
    font-weight: 500;
    color: var(--white);

    width: 100%;
    height: 100%;
    position: relative;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.languages:nth-child(1) {
    top: -2px;
    left: -14px;
    width: 90px;
    height: 40px;
    animation: lang2 .5s linear;
}

@keyframes lang2 {
    0%{ 
        transform: translateX(-20%);
    }
    100% {
        transform: translateX(0);
    }
}

.language::after,
.languages::after {
    content: "";
    width: 100%;
    height: 100%;

    position: absolute;
    top: 2px;
    left: 3px;
    background: var(--bgColor);
    border: 1px solid var(--orange);
    transform: skewX(-20deg);
    z-index: -1;
}

.languageDropdown {
    position: absolute;
}

.languages:hover {
    color: var(--lightBlue);
}

@media (max-width: 480px) {
    .nav-languages {
        top: -1px;
        width: 65px;
        height: 30px;
    }

    .languages:nth-child(1) {
        top: -2px;
        left: -10px;
        width: 65px;
        height: 30px;
    }
}