.footer {
    margin-block-start: 100px;
    padding-block: 40px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #071147;
}

footer > img {
    margin-bottom: 30px;
}

footer > p {
    font-size: 12px;
    font-weight: 300;
    color: var(--textColor);
    opacity: 0.5;
}

footer > p:not(:last-child) {
    margin-bottom: 5px;
}
